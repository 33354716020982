import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import facebookSVG from '@/public/images/facebook.svg'

const Button = styled('button', {
    backgroundColor: '#1877F2',
    color: '$white',
    fontSize: '15px',
    border: 'none',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    height: '44px',
    width: '100%',
    cursor: 'pointer',
    '&:active': {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)',
    },
    '&:disabled': {
        opacity: '0.6',
        cursor: 'not-allowed',
    },
})

const Label = styled('span', {
    display: 'block',
})

interface ButtonProps {
    onClick: () => void
    label: string
    disabled?: boolean
}

export default function FacebookButton({
    onClick,
    label,
    disabled = false,
}: ButtonProps) {
    return (
        <Button onClick={() => onClick()} disabled={disabled}>
            <Image
                src={facebookSVG}
                alt="Facebook Icon"
                height={18}
                width={18}
            />

            <Label>{label}</Label>
        </Button>
    )
}
