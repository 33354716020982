import React from 'react'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { Button } from './StyledComponents'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import { styled } from '@/src/stitches.config'
import { GradientText } from '../pg-plus/PGPlusStyledComponents'

type EmailVerificationScreenProps = {
    email: string
    resendVerificationEmail: () => Promise<void>
    resentVerificationMessage: {
        message: string
        type: 'success' | 'error'
    } | null
    loading: boolean
}

const Container = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
})

const EmailVerificationScreen = ({
    email,
    resendVerificationEmail,
    resentVerificationMessage,
    loading,
}: EmailVerificationScreenProps) => {
    const { refreshUser } = useUser()
    const [manualCheckInProgress, setManualCheckInProgress] =
        React.useState(false)
    const [lastChecked, setLastChecked] = React.useState<Date | null>(null)

    const handleManualVerificationCheck = async () => {
        setManualCheckInProgress(true)
        try {
            await refreshUser()
            setLastChecked(new Date())
        } catch (err) {
            console.error('Error checking verification status:', err)
        } finally {
            setManualCheckInProgress(false)
        }
    }

    return (
        <Container>
            <h2 style={{ fontSize: '2.1rem', wordWrap: 'break-word' }}>
                We sent a verification email to
                <br />
                <GradientText size="lg">{email}</GradientText>
            </h2>
            <p>
                Please check your inbox and click on the verification link that
                we sent to your email address and return to this window.
            </p>

            {resentVerificationMessage && (
                <AlertWithIcon
                    iconName={
                        resentVerificationMessage.type === 'success'
                            ? 'success'
                            : 'error'
                    }
                    size="sm"
                >
                    {resentVerificationMessage.message}
                </AlertWithIcon>
            )}

            {lastChecked && (
                <div style={{ fontSize: '14px', color: '#666' }}>
                    Last checked: {lastChecked.toLocaleTimeString()}
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <Button
                    label="I've verified my email"
                    width="full"
                    textTransform="uppercase"
                    disabled={manualCheckInProgress}
                    isLoading={manualCheckInProgress}
                    onClick={handleManualVerificationCheck}
                />

                <Button
                    label="Resend verification email"
                    width="full"
                    textTransform="uppercase"
                    variant="primaryOutline"
                    disabled={loading}
                    isLoading={loading}
                    onClick={resendVerificationEmail}
                />
            </div>
            <p style={{ fontSize: 14, marginTop: 30, marginBottom: 0 }}>
                PerfectGift wants your gift experience to be as safe and secure
                as possible. To facilitate that, we require an account to be
                signed into or created to ensure you are the only person
                accessing your gift and gift details. Please see our{' '}
                <a
                    href={'/terms-of-use'}
                    target="_blank"
                    style={{ color: 'var(--colors-primary' }}
                >
                    Terms of Service
                </a>{' '}
                and{' '}
                <a
                    href={'/privacy-policy'}
                    target="_blank"
                    style={{ color: 'var(--colors-primary' }}
                >
                    Privacy Policy
                </a>{' '}
                for more details about our policies.
            </p>
        </Container>
    )
}

// Generated by Copilot
export default EmailVerificationScreen
