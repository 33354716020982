import { styled } from '@/src/stitches.config'
import Image from 'next/image'
import appleSVG from '@/public/images/apple.svg'

const Button = styled('button', {
    backgroundColor: '$white',
    color: '$dark',
    fontSize: '15px',
    border: '2px solid $mediumGray',
    borderRadius: '5px',
    height: '44px',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '&:active': {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)',
    },
    '&:disabled': {
        backgroundColor: '$mediumGray',
        opacity: '0.6',
        cursor: 'not-allowed',
    },
})

const Label = styled('span', {
    display: 'block',
})

interface ButtonProps {
    onClick: () => void
    label: string
    disabled?: boolean
}

export default function AppleButton({
    onClick,
    label,
    disabled = false,
}: ButtonProps) {
    return (
        <Button onClick={() => onClick()} disabled={disabled}>
            <Image src={appleSVG} alt="Apple Icon" height={18} width={18} />

            <Label>{label}</Label>
        </Button>
    )
}
