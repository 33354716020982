import { TextInputWithLabel } from '@/src/common/components/elements/Forms/TextInputWithLabel'
import { clearFormErrorForField } from '@/src/common/utilities/clearFormErrorForField'
import { inputHasError } from '@/src/common/utilities/inputHasError'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { FormErrorDisplay } from '@/src/modules/build-a-card/FormErrorDisplay'
import { styled } from '@/src/stitches.config'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import Image from 'next/image'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import * as yup from 'yup'
import { emailSignIn, emailSignUp, resetPassword } from './FirebaseAuth'
import { EXISTING_LOGIN, NEW_LOGIN } from './LoginConstants'
import { useLoginContext } from './LoginContext'
import { BlueLink, Button } from './StyledComponents'
import EmailVerificationScreen from '@/src/modules/login/EmailVerificationScreen'
import EmailInput from '@/src/common/components/elements/Inputs/EmailInput'
import AlertWithIcon from '@/src/common/components/elements/Alerts/AlertWithIcon'
import { scrollToTop } from '@/src/common/utilities/scrollToTop'

const FormField = styled('div', {
    marginBottom: '12px',
})

const emailSchema = yup.object({
    email: yup
        .string()
        .trim()
        .required('An email address is required')
        .email('A valid email address is required'),
})

const passwordSchema = yup.object({
    password: yup
        .string()
        .trim()
        .required('A password is required')
        .min(8, 'Password must be at least 8 characters'),
})

const emailPasswordValidationSchema = emailSchema.concat(passwordSchema)

type TEmailProps = {
    setIsLoginSuccess?: Function
}

const EmailPassword = ({ setIsLoginSuccess }: TEmailProps) => {
    const {
        loginType,
        showVerificationScreen,
        setShowVerificationScreen,
        email,
        setEmail,
        loading,
        setLoading,
        sentPasswordReset,
        setSentPasswordReset,
        showResetPassword,
        setShowResetPassword,
    } = useLoginContext()

    const [password, setPassword] = useState('')
    const [visiblePassword, setVisiblePassword] = useState(false)
    const { user } = useUser()

    const [resentVerificationMessage, setResentVerificationMessage] = useState<{
        message: string
        type: 'success' | 'error'
    } | null>(null)

    const [emailPasswordFormErrors, setEmailPasswordFormErrors] = useState<
        yup.ValidationError[]
    >([])

    useEffect(() => {
        if (user?.email && !user.emailVerified) {
            setEmail(user.email)
            setShowVerificationScreen(true)
        }
    }, [user, setEmail, setShowVerificationScreen])

    const baseLogin = async (userType: string) => {
        const validForm = await emailPasswordValidationSchema.isValid(
            { email, password },
            { abortEarly: false }
        )

        if (!validForm) {
            return emailPasswordValidationSchema
                .validate({ email, password }, { abortEarly: false })
                .then(() => setEmailPasswordFormErrors([]))
                .catch((e) => setEmailPasswordFormErrors(e.inner))
        }

        setLoading(true)

        let loginError

        if (userType === NEW_LOGIN) {
            const res = await emailSignUp({ email, password })

            if (res?.error) {
                loginError = res.error
            }
        }

        if (userType === EXISTING_LOGIN) {
            const res = await emailSignIn({
                email,
                password,
            })

            if (res?.error) {
                loginError = res.error
            }
        }

        if (loginError) {
            setEmailPasswordFormErrors([loginError])
            setLoading(false)
            return
        }

        const { currentUser } = getAuth()

        if (currentUser && !currentUser.emailVerified) {
            await sendEmailVerification(currentUser)

            setShowVerificationScreen(true)
        }

        setIsLoginSuccess && setIsLoginSuccess(true)
        setLoading(false)
    }

    const handleForgotPassword = async () => {
        sentPasswordReset && setSentPasswordReset(false)

        const validForm = await emailSchema.isValid(
            { email },
            { abortEarly: false }
        )

        if (!validForm) {
            return emailSchema
                .validate({ email }, { abortEarly: false })
                .then(() => setEmailPasswordFormErrors([]))
                .catch((e) => setEmailPasswordFormErrors(e.inner))
        }

        setLoading(true)

        await resetPassword(email)
            .then(() => {
                setSentPasswordReset(true)
            })
            .catch((e) => setEmailPasswordFormErrors(e.inner))
            .finally(() => setLoading(false))
    }

    const resendVerificationEmail = async () => {
        const { currentUser } = getAuth()

        if (currentUser && !currentUser.emailVerified) {
            setLoading(true)

            // if user reset verification in same session, reset message
            if (resentVerificationMessage) {
                setResentVerificationMessage(null)
            }

            // try to send a verification email to the user
            try {
                await sendEmailVerification(currentUser)

                // if successfully sent, add success message
                setResentVerificationMessage({
                    message: 'A new verification email has been sent!',
                    type: 'success',
                })
            } catch (error: any) {
                // if not, show error message
                setResentVerificationMessage({
                    message:
                        'Unable to resend email verification. Please try again later.',
                    type: 'error',
                })
            }

            setShowVerificationScreen(true)
            setLoading(false)
        }
    }

    // if user wants to change password, show step
    if (showResetPassword) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <EmailInput
                    email={email}
                    setEmail={setEmail}
                    formErrors={emailPasswordFormErrors}
                    setFormErrors={setEmailPasswordFormErrors}
                    fieldId={'email'}
                />
                {sentPasswordReset && (
                    <AlertWithIcon
                        iconName={'success'}
                        size={'sm'}
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        An email has been sent with a link to reset your
                        password!
                    </AlertWithIcon>
                )}
                <div style={{ margin: 0 }} />
                <Button
                    label={'Reset password'}
                    width="full"
                    textTransform="uppercase"
                    disabled={loading}
                    isLoading={loading}
                    onClick={handleForgotPassword}
                />
                <Button
                    label={'Return to login'}
                    width="full"
                    textTransform="uppercase"
                    disabled={loading}
                    onClick={() => {
                        setSentPasswordReset(false)
                        setShowResetPassword(false)
                    }}
                    variant={'primaryOutline'}
                />
            </div>
        )
    }

    // if user needs to verify, show verification screen
    if (showVerificationScreen) {
        return (
            <EmailVerificationScreen
                email={email}
                resendVerificationEmail={resendVerificationEmail}
                resentVerificationMessage={resentVerificationMessage}
                loading={loading}
            />
        )
    }

    // email and password input field
    return (
        <>
            <FormField>
                <EmailInput
                    email={email}
                    setEmail={setEmail}
                    formErrors={emailPasswordFormErrors}
                    setFormErrors={setEmailPasswordFormErrors}
                    fieldId={'email'}
                />
            </FormField>
            <FormField style={{ position: 'relative' }}>
                <TextInputWithLabel
                    id={'password'}
                    type={visiblePassword ? 'text' : 'password'}
                    value={password}
                    label={'Password'}
                    onChange={(e: BaseSyntheticEvent) => {
                        let trimmedValue = e.currentTarget.value.trim()
                        setPassword(trimmedValue)
                        clearFormErrorForField({
                            path: 'password',
                            formErrors: emailPasswordFormErrors,
                            setFormErrors: setEmailPasswordFormErrors,
                        })
                    }}
                    style={{
                        fontSize:
                            password && !visiblePassword ? '30px' : 'inherit',
                    }}
                    maxLength={50}
                    hasError={inputHasError(
                        emailPasswordFormErrors,
                        'password'
                    )}
                    className={'fs-mask fs-exclude'}
                />
                <Image
                    src={
                        visiblePassword
                            ? '/images/eye-outline.svg'
                            : '/images/eye-off-outline.svg'
                    }
                    onClick={() => setVisiblePassword((x) => !x)}
                    alt={'Password Eye'}
                    height={36}
                    width={36}
                    style={{
                        position: 'absolute',
                        top: '8px',
                        right: '16px',
                    }}
                />
                <FormErrorDisplay
                    formErrors={emailPasswordFormErrors}
                    path={'password'}
                />
            </FormField>
            {loginType === EXISTING_LOGIN && (
                <div style={{ margin: '25px 0px 30px' }}>
                    <BlueLink
                        onClick={() => {
                            scrollToTop('__next')
                            setShowResetPassword(true)
                        }}
                    >
                        Forgot password?
                    </BlueLink>
                </div>
            )}
            <Button
                label={loginType === NEW_LOGIN ? 'Sign Up' : 'Log In'}
                showArrow={true}
                width="full"
                textTransform="uppercase"
                isLoading={loading}
                disabled={loading}
                onClick={() => baseLogin(loginType)}
            />
        </>
    )
}

export default EmailPassword
